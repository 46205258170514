import type { Country } from '@backmarket/http-api'
import type {
  Bill,
  Deliver,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { getShippingStates } from '@backmarket/nuxt-module-address/utils/state/getShippingStates'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

export function cleanAddressState(address: Deliver | Bill) {
  const states = getShippingStates(address.country as Country)

  if (isEmpty(states)) {
    return { ...address, stateOrProvince: '' }
  }

  return { ...address }
}
