import { type Country, type MonetaryAmount } from '@backmarket/http-api'
import {
  getCart,
  getShippings,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import type {
  CartCollectionPoint,
  CartItem,
  CartResponse,
  CartSummary,
  CheckoutInsuranceOffer,
  LastCollectionPoint,
  ShippingChoice,
  ShippingOption,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import type {
  Shipping,
  ShippingsResponse,
} from '@backmarket/http-api/src/api-specs-checkout/cart/shippings.types'
import { getProductInfos } from '@backmarket/nuxt-layer-recommendation/helpers.ts'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import type { EventData } from '@backmarket/nuxt-module-tracking'
import { formatPrice } from '@backmarket/nuxt-module-tracking/formatPrice'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { mapValues } from '@backmarket/utils/object/mapValues'
import { defineStore } from 'pinia'

import { CHECKOUT_STEP_IDS } from '../routes-names'
import { isBouyguesOffer } from '../utils/isBouyguesOffer'

import { useAddressStore } from './addressStore'
import { useDiscountStore } from './discountStore'
import { useSwapStore } from './swapStore'
import { useUserInformationStore } from './userInformationStore'

function getPaymentType(insurance?: CheckoutInsuranceOffer) {
  if (!insurance || insurance.defaultOffer) return ''

  return insurance.isMonthly ? 'monthly' : 'upfront'
}

interface ShippingWIthIds extends Shipping {
  countries: { [key: string]: string }
}

interface CartState {
  price: MonetaryAmount
  tax: MonetaryAmount | null
  totalPriceIncludesTax: boolean | null
  serviceFee: MonetaryAmount | null
  totalShippingPrice: MonetaryAmount
  totalPriceWithoutShipping: MonetaryAmount
  totalListingsPrice: MonetaryAmount
  totalPriceAfterBuyback?: MonetaryAmount
  totalGrossPriceWithoutAddonService?: MonetaryAmount
  totalGrossPriceWithoutBmFee?: MonetaryAmount
  items: CartItem[]
  shippings: ShippingsResponse[] | []
  lastCollectionPoint: LastCollectionPoint | Record<string, never>
  showCatchupModal: boolean
  isCartLoaded: boolean
}

export const useCartStore = defineStore('cart', {
  state: (): CartState => ({
    price: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    tax: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalPriceIncludesTax: false,
    serviceFee: null,
    totalShippingPrice: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalPriceWithoutShipping: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalListingsPrice: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalGrossPriceWithoutAddonService: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalGrossPriceWithoutBmFee: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    totalPriceAfterBuyback: undefined,
    items: [],
    shippings: [],
    lastCollectionPoint: {},
    showCatchupModal: true,
    isCartLoaded: false,
  }),
  getters: {
    bouyguesMobilePlan: (state) => {
      const itemWithBouyguesOffer = state.items.find((item) => {
        if (!item.mobilePlan) return false

        return isBouyguesOffer(item.mobilePlan.selectedOffer)
      })

      return itemWithBouyguesOffer?.mobilePlan?.selectedOffer
    },
    hasBouyguesMobilePlan(): boolean {
      return !isEmpty(this.bouyguesMobilePlan)
    },
    hasServiceFee: (state) => !isEmpty(state.serviceFee),
    itemsIds: (state) => state.items.map((item) => item.listingId),
    availableItems: (state) =>
      state.items.filter((item) => item.available === 'yes'),
    unavailableItems: (state) =>
      state.items.filter((item) => item.available === 'no'),
    areShippingsLoaded: (state) => !isEmpty(state.shippings),
    shippingsByCountry: (state) => {
      const shippingsByCountry: {
        [key: string]: { [key: string]: Shipping[] }
      } = {}

      state.shippings.forEach(({ listingId, countries }) => {
        countries.forEach(({ country, shippings }) => {
          if (!shippingsByCountry[country]) {
            shippingsByCountry[country] = {}
          }
          shippingsByCountry[country][listingId] = [...shippings]
          shippingsByCountry[country][listingId].sort((shippingA, shippingB) =>
            shippingA.shippingPrice > shippingB.shippingPrice ? 1 : -1,
          )
        })
      })

      return shippingsByCountry
    },
    shippingsById: (state) => {
      const shippingsById: {
        [key: string]: ShippingWIthIds
      } = {}

      state.shippings.forEach(({ countries }) => {
        countries.forEach(({ country, shippings }) => {
          shippings.forEach((shipping) => {
            const item = shippingsById[shipping.shippingId] || {}

            shippingsById[shipping.shippingId] = {
              ...shipping,
              countries: {
                ...item.countries,
                [country]: country,
              },
            }
          })
        })
      })

      return shippingsById
    },

    /**
     * @returns A map containing all selected options, indexed by item id.
     */
    selectedOptionsByItemId: (
      state,
    ):
      | Record<string, Array<{ choice: ShippingChoice } & ShippingOption>>
      | Record<string, never> =>
      state.items.reduce(
        (acc, item) => ({
          ...acc,
          [item.listingId]: item.options
            .map((option) => ({
              option,
              choice: option.choices.find(({ selected }) => selected),
            }))
            .filter(
              // Ensure choice was found
              ({ choice }) => !isEmpty(choice),
            ),
        }),
        {},
      ),
    priceAfterDiscount(): MonetaryAmount {
      const { isApplied, getDeductedPrice } = useDiscountStore()

      return isApplied ? getDeductedPrice : this.price
    },
    /**
     * @returns {boolean} Returns true if the current checkout state is shippable.
     * For regular shipping:
     * - shipping address is valid (see isShippingAddressComplete in the address store)
     * - cart items are shippable for the current shipping address country (see
     *   isShippableForCountry)
     * For collection point shipping:
     * - customer details are valid (see isCollectionPointAddressComplete in the address store)
     * - note: no check is performed for the selected collection point, that is
     *         a separate check, which is used for a different redirection (see
     *         isSelectedCollectionPointMissing).
     */
    isShippable(): boolean {
      const {
        hasCollectionPoint,
        isBillingAddressComplete,
        isCollectionPointAddressComplete,
        isShippingAddressComplete,
        shipping,
      } = useAddressStore()

      const isShippable = hasCollectionPoint
        ? isCollectionPointAddressComplete
        : isShippingAddressComplete &&
          this.isShippableForCountry(shipping.country as Country)

      return isShippable && isBillingAddressComplete
    },
    availableItemsById(): { [key: string]: CartItem } {
      return this.availableItems.reduce(
        (acc, item) => ({ ...acc, [item.listingId]: item }),
        {},
      )
    },
    hasAvailableItems(): boolean {
      return !isEmpty(this.availableItems)
    },
    hasUnavailableItems(): boolean {
      return !isEmpty(this.unavailableItems)
    },
    availableItemsCount(): number {
      return this.availableItems.reduce((sum, item) => sum + item.quantity, 0)
    },
    /**
     * @returns A map containing all selected shippings, indexed by item id.
     */
    selectedShippingsByAvailableItemId(): { [key: string]: ShippingWIthIds } {
      return mapValues(
        this.availableItemsById,
        (item) => this.shippingsById[item.shippingSelected.shippingFeePk],
      )
    },
    /**
     * @returns A function that returns `true` if
     * all items are shippable for a given country (ex: 'FR'). This means that
     * each selected shipping must match that country.
     */
    isShippableForCountry(): (country: Country) => boolean {
      return (country: Country) => {
        return Object.values(this.selectedShippingsByAvailableItemId).every(
          (shipping) => shipping && shipping.countries[country],
        )
      }
    },
    /**
     * @returns insurance offers that have been selected
     */
    selectedInsuranceOffers(): CheckoutInsuranceOffer[] {
      return this.availableItems
        .map((item: CartItem) =>
          item.insuranceOffers?.find((offer) => offer.selected),
        )
        .filter(
          (offer): offer is CheckoutInsuranceOffer =>
            !!offer && !offer.defaultOffer,
        )
    },
    shippingsByItemId(): { [key: string]: ShippingWIthIds } {
      if (!this.areShippingsLoaded) {
        return {}
      }

      return mapValues(
        this.availableItemsById,
        (item) => this.shippingsById[item.shippingSelected.shippingFeePk],
      )
    },
    /**
     * @returns A function that returns an object containing all items that are not shippable, indexed by item id.
     */
    getUnshippableItems(): (addressCountry: string) => {
      [key: string]: CartItem
    } {
      return (addressCountry: string) => {
        const { market } = useMarketplace()
        if (addressCountry === '') {
          return {}
        }

        return Object.entries(this.shippingsByItemId).reduce(
          (acc, [itemId, shipping]) =>
            shipping?.countries[market.countryCode] &&
            addressCountry === market.countryCode
              ? acc
              : { ...acc, [itemId]: this.availableItemsById[itemId] },
          {},
        )
      }
    },
    hasUnshippableItemsForCountry(): (country: string) => boolean {
      return (country: string) => !isEmpty(this.getUnshippableItems(country))
    },
    hasCollectionPointShipping(): boolean {
      return Object.values(this.selectedOptionsByItemId).some((options) =>
        options.some(
          ({ choice }: { choice: ShippingChoice }) => choice.isCollectionPoint,
        ),
      )
    },
    optionWithMissingCollectionPointSelected():
      | [
          string,
          ({
            choice: ShippingChoice
          } & ShippingOption)[],
        ]
      | undefined {
      return !this.hasSelectedCollectionPoint
        ? Object.entries(this.selectedOptionsByItemId).find(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([, option]) =>
              option.some(
                ({ choice }: { choice: ShippingChoice }) =>
                  choice.isCollectionPoint,
              ),
          )
        : undefined
    },

    selectedCollectionPoint(): CartCollectionPoint {
      return this.lastCollectionPoint?.selectedPoint
    },
    hasSelectedCollectionPoint(): boolean {
      return !isEmpty(this.selectedCollectionPoint)
    },
    isSelectedCollectionPointMissing(): boolean {
      return this.hasCollectionPointShipping && !this.hasSelectedCollectionPoint
    },
    isCollectionPointValid(): boolean {
      return (
        this.isSelectedCollectionPointMissing &&
        Boolean(this.optionWithMissingCollectionPointSelected)
      )
    },
    isItemAvailable() {
      return (listingId: string): boolean =>
        !!this.availableItemsById[listingId]
    },
    hasInsurance() {
      return this.selectedInsuranceOffers.length > 0
    },
    hasMonthlyInsurance(): boolean {
      return (
        this.hasInsurance &&
        this.selectedInsuranceOffers.some((offer) => offer.isMonthly)
      )
    },

    trackingData() {
      return (
        routeName: string,
      ): {
        products: EventData[]
        swap: boolean
        step: number
        pageType: string
      } => {
        const swapStore = useSwapStore()
        const { defaultCurrency } = useMarketplace().market

        return {
          products: this.items.map((item) => {
            const insurance = item.insuranceOffers?.find(
              (offer) => !offer.defaultOffer && offer.selected,
            )

            const deliveryOption = item.options.find(
              (option) => option.type === 'delivery',
            )
            const deliveryOptionChoices = deliveryOption?.choices || []

            return {
              available: item.available === 'yes',
              brand: item.brand ?? '',
              category: item.category ?? '',
              color: item.color ?? '',
              currency: defaultCurrency,
              id: item.productId ?? '',
              insurancePrice: insurance?.price.amount ?? '',
              insuranceTitle: insurance?.title ?? '',
              insurancePaymentType: getPaymentType(insurance),
              list: getProductInfos(item.akeneoId).source,
              listingId: item.listingId ?? '',
              merchantId: item.merchantId ?? '',
              model: item.model ?? '',
              name: item.title ?? '',
              specialOfferType: item.specialOfferType,
              price: item.price ?? '',
              quantity: item.quantity ?? '',
              shipper: [
                item.shippingSelected.shipperId ?? '',
                item.shippingSelected.shippingFeeDelay ?? '0',
                formatPrice({
                  amount: item.shippingSelected.shippingFeePrice,
                  currency: defaultCurrency,
                }),
              ].join(' - '),
              shippingDelay: item.shippingSelected.shippingFeeDelay ?? '',
              shippingPrice: item.shippingSelected.shippingFeePrice ?? '',
              shippingOptions: deliveryOptionChoices.map((choice) =>
                [
                  choice.shipperId ?? '',
                  choice.shippingDelay ?? '0',
                  formatPrice({
                    amount: choice.price,
                    currency: defaultCurrency,
                  }),
                ].join(' - '),
              ),
              uuid: item.akeneoId ?? '',
              variant: item.gradeId ?? '',
              warrantyDuration: item.warranties[0]?.delay ?? '',

              dealType: 'normal',
              isInsuranceEligible: !!item.insuranceOffers?.length || false,
              insuranceEligibleOffers:
                item.insuranceOffers &&
                item.insuranceOffers
                  .filter((offer) => !offer.defaultOffer)
                  .map((offer) => (offer.isMonthly ? 'monthly' : 'upfront'))
                  .join('-'),
              mobilePlanOfferSelected:
                item.mobilePlan?.selectedOffer.name ?? '',
            }
          }),
          swap: swapStore.hasOffer,
          step: CHECKOUT_STEP_IDS[routeName] ?? '',
          pageType: routeName ?? '',
        }
      }
    },
  },
  actions: {
    setPrice({
      totalListingsPrice,
      totalPrice,
      serviceFee,
      tax,
      totalDelivery,
      totalGrossPriceWithoutAddonService,
      totalGrossPriceWithoutBmFee,
      totalPriceIncludesTax,
      totalPriceAfterBuyback,
    }: CartSummary) {
      // Picking up currency from the total listing since we don't have a standalone currency prop
      const { currency } = totalListingsPrice

      this.price = { amount: totalPrice, currency }
      this.serviceFee = isEmpty(serviceFee) ? null : serviceFee
      this.tax = tax
      this.totalShippingPrice = totalDelivery
      this.totalGrossPriceWithoutAddonService =
        totalGrossPriceWithoutAddonService
      this.totalGrossPriceWithoutBmFee = totalGrossPriceWithoutBmFee
      this.totalListingsPrice = totalListingsPrice
      this.totalPriceIncludesTax = totalPriceIncludesTax
      this.totalPriceAfterBuyback = totalPriceAfterBuyback
    },
    setItems(items: CartItem[]) {
      this.items = items
      this.shippings = []
    },
    setShippings(shippings: ShippingsResponse[]) {
      this.shippings = shippings
    },
    setLastCollectionPoint(
      lastCollectionPoint?: LastCollectionPoint | Record<string, never>,
    ) {
      this.lastCollectionPoint = isEmpty(lastCollectionPoint)
        ? {}
        : lastCollectionPoint
    },
    setShowCatchupModal(showCatchupModal: boolean) {
      this.showCatchupModal = showCatchupModal
    },
    setCart(payload: CartResponse) {
      this.setPrice(payload.summary)
      this.setItems(payload.cartItems)
      this.setShippings([])
      this.setLastCollectionPoint(payload.lastCollectionPoint)
    },
    skipCatchupModal() {
      this.setShowCatchupModal(false)
    },
    async fetchShippings() {
      if (
        !this.areShippingsLoaded &&
        this.isCartLoaded &&
        this.hasAvailableItems
      ) {
        const shippings = await $httpFetch(getShippings)

        this.setShippings(shippings)
      }
    },
    async fetchCart() {
      const experiments = useExperiments()

      const newPriceGrid24 =
        experiments['experiment.serviceFeePriceGroup24'] === 'newPriceGrid24'
      const monthlyInsuranceSupported =
        experiments['experiment.insuranceMonthlySubscription'] ===
        'insuranceMonthlyEnabled'

      const { setAddress } = useAddressStore()
      const { setSwap } = useSwapStore()
      const { setBasePrice } = useDiscountStore()
      const { setUserInformation } = useUserInformationStore()

      const cart = await $httpFetch(getCart, {
        queryParams: {
          newPriceGrid24,
          monthly_insurance_supported: monthlyInsuranceSupported,
        },
      })

      if (!isEmpty(cart)) {
        this.setCart(cart)

        setAddress(cart)
        setSwap(cart.swap)
        setUserInformation(cart.userInformation)
        setBasePrice({
          amount: cart?.summary.totalPrice ?? '',
          currency: this.price.currency,
        })
      }

      this.isCartLoaded = true
    },
  },
})
