import { promotionCodesAPI } from '@backmarket/http-api'

import type { InvalidDiscount, ValidDiscount } from '../types/discount'

export const DISCOUNT_TYPES = {
  NOT_VALID: 0,
  WITHOUT_REFERRAL: 1,
  WITH_REFERRAL: 3,
  REFERRAL_BASE: 5,
}

export const formatDiscount = (
  payload: promotionCodesAPI.PostCheckResponse,
): ValidDiscount | InvalidDiscount => {
  const { valid: type, ...data } = payload

  switch (type) {
    case DISCOUNT_TYPES.WITH_REFERRAL:
    case DISCOUNT_TYPES.WITHOUT_REFERRAL:
      return {
        discount: {
          deductedPrice: Number(payload.newPrice),
          deduction: Number(payload.amount),
          deductionMax: Number(payload.amount),
          id: payload.promotionCodePk ?? '',
          referralBase: DISCOUNT_TYPES.REFERRAL_BASE,
        },
        errorMessage: '',
        isValid: true,
      }

    case DISCOUNT_TYPES.NOT_VALID:
      return {
        errorMessage: data.message ?? '',
        isValid: false,
      }

    default:
      throw new Error(`Unknown discount type: ${type}`)
  }
}
