import {
  type MonetaryAmount,
  paymentAPI,
  promotionCodesAPI,
} from '@backmarket/http-api'
import { type PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment'
import { useAuthStore } from '@backmarket/nuxt-layer-oauth/useAuthStore'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { paymentGroupConfig } from '@backmarket/nuxt-module-payment/methods'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'
import { defineStore } from 'pinia'

import type { Discount, DiscountPaymentData } from '../types/discount'

import translations from './discountStore.translations'
import { formatDiscount } from './discountStore.utils'

interface State {
  basePrice: MonetaryAmount
  disabledMessage: string
  discount: Discount
  errorMessage: string
  isDisabled: boolean
  token: string
  paymentMethod: PaymentMethod | null
}

const EMPTY_DISCOUNT = {
  deductedPrice: 0,
  deduction: 0,
  deductionMax: 0,
  id: '',
  referralBase: 0,
}

export const useDiscountStore = defineStore('discountStore', {
  state: (): State => ({
    basePrice: {
      currency: useMarketplace().market.defaultCurrency,
      amount: '0.00',
    },
    disabledMessage: '',
    discount: { ...EMPTY_DISCOUNT },
    errorMessage: '',
    isDisabled: false,
    token: '',
    paymentMethod: null,
  }),
  getters: {
    getErrorMessage: (state): string =>
      state.isDisabled ? state.disabledMessage : state.errorMessage,
    id: (state): string => state.discount.id,
    getDeduction: (state): MonetaryAmount => ({
      currency: state.basePrice.currency,
      amount: String(state.discount.deduction),
    }),
    getDeductedPrice: (state): MonetaryAmount => ({
      currency: state.basePrice.currency,
      amount: String(state.discount.deductedPrice),
    }),
    isApplied(state): boolean {
      return !isEmpty(this.id) && !state.isDisabled
    },
    paymentData(): DiscountPaymentData | null {
      if (!this.isApplied) {
        return null
      }

      return {
        id: this.id,
        deduction: this.getDeduction,
        totalDeduction: this.getDeduction,
      }
    },
  },
  actions: {
    reset() {
      this.disabledMessage = ''
      this.discount = { ...EMPTY_DISCOUNT }
      this.errorMessage = ''
      this.isDisabled = false
    },
    setBasePrice(basePrice: MonetaryAmount) {
      if (
        this.basePrice.currency !== basePrice.currency ||
        this.basePrice.amount !== basePrice.amount
      ) {
        this.reset()
        this.basePrice = basePrice
      }
    },
    setPaymentMethod(paymentMethod: paymentAPI.PaymentMethod | null) {
      this.paymentMethod = paymentMethod

      if (paymentMethod) {
        const i18n = useI18n()

        this.isDisabled = !paymentMethod.promoCodeEnabled
        this.disabledMessage = i18n(
          translations.unavailableForPaymentProvider,
          {
            paymentProvider: paymentGroupConfig(paymentMethod.group)
              .providerName,
          },
        )
      }
    },
    saveToken(token: string) {
      this.reset()
      this.token = token
    },
    async checkToken(token: string) {
      this.saveToken(token)

      const experiments = useExperiments()

      const authStore = useAuthStore()

      const response = formatDiscount(
        await $httpFetch(promotionCodesAPI.postCheck, {
          body: {
            country_code: toBcp47Locale(useMarketplace().market.defaultLocale),
            client_pk: authStore.userId,
            bag_price: this.basePrice,
            token,
            ...(experiments['experiment.serviceFeePriceGroup24'] ===
            'newPriceGrid24'
              ? { newPriceGrid24: true }
              : {}),
          },
        }),
      )

      this.errorMessage = response.errorMessage

      if (response.isValid) {
        this.discount = response.discount
        this.basePrice = {
          amount: `${response.discount.deductedPrice}`,
          currency: useMarketplace().market.defaultCurrency,
        }
      }
    },
  },
})
